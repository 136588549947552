var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" 과금 정책 ")]),_c('v-card-subtitle',[_vm._v(" Price policy for charging a vehicle ")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-24",attrs:{"footer-props":{
        'items-per-page-options': [5, 10, 20, 50, 100],
      },"items":_vm.elements,"loading":_vm.elementSearch.loading,"headers":_vm.elementSearch.headers,"server-items-length":_vm.elementSearch.totalElements,"options":_vm.elementSearchForm,"multi-sort":""},on:{"update:options":function($event){_vm.elementSearchForm=$event}},scopedSlots:_vm._u([{key:"item.station",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.management.name)+" ")]}},{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.editElement(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.prices",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.prices))])]}},{key:"item.deleted",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.deleted ? "비활성화" : "활성화")+" ")]}}])})],1),_c('v-dialog',{attrs:{"width":"80%"},model:{value:(_vm.elementEdit),callback:function ($$v) {_vm.elementEdit=$$v},expression:"elementEdit"}},[_c('v-card',[_c('v-app-bar',{attrs:{"color":"deep-purple accent-4","dense":""}},[_c('v-toolbar-title',[_vm._v("과금 정책 ["+_vm._s(_vm.element.name)+"]")])],1),_c('v-card-text',[_c('managements-price',{ref:"managementsPrice",attrs:{"standalone":false}})],1),_c('v-card-text',[_c('prices-revisions',{ref:"pricesRevisions",attrs:{"standalone":false}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.elementEdit = false}}},[_vm._v(" Close ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }