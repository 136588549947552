<template>
  <v-card>
    <v-card-title> 과금 정책 </v-card-title>
    <v-card-subtitle> Price policy for charging a vehicle </v-card-subtitle>

    <v-card-text>
      <v-data-table
        :footer-props="{
          'items-per-page-options': [5, 10, 20, 50, 100],
        }"
        :items="elements"
        :loading="elementSearch.loading"
        :headers="elementSearch.headers"
        :server-items-length="elementSearch.totalElements"
        :options.sync="elementSearchForm"
        multi-sort
        class="elevation-24"
      >
        <template v-slot:item.station="{ item }">
          {{ item.management.name }}
        </template>
        <template v-slot:item.name="{ item }">
          <a @click="editElement(item)">
            {{ item.name }}
          </a>
        </template>
        <template v-slot:item.prices="{ item }">
          <span>{{ item.prices }}</span>
        </template>

        <template v-slot:item.deleted="{ item }">
          {{ item.deleted ? "비활성화" : "활성화" }}
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog v-model="elementEdit" width="80%">
      <v-card>
        <v-app-bar color="deep-purple accent-4" dense>
          <v-toolbar-title>과금 정책 [{{ element.name }}]</v-toolbar-title>
        </v-app-bar>

        <v-card-text>
          <managements-price ref="managementsPrice" :standalone="false" />
        </v-card-text>

        <v-card-text>
          <prices-revisions ref="pricesRevisions" :standalone="false" />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="elementEdit = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import OCPP_API from "@/app/env.ocpp.api.js";

import ManagementsPrice from "@/app/contents/managements/ManagementsPrice.vue";
import PricesRevisions from "@/app/contents/prices/PricesRevisions.vue";

export default {
  name: "Prices",
  components: {
    ManagementsPrice,
    PricesRevisions,
  },

  data: () => ({
    elementDailog: false,
    element: {},
    elements: [],

    elementSearch: {
      headers: [
        { text: "ID", value: "id" },
        { text: "충전 스테이션", value: "station" },
        { text: "과금 정책 이름", value: "name" },
        { text: "시작일", value: "startDay" },
        { text: "반복", value: "cronComment" },
        { text: "종료일", value: "endDay" },
        // { text: "24시간 요금표", value: "prices", width:"200"},
        { text: "사용 이력", value: "occupied" },
        { text: "사용 여부 ", value: "deleted" },
      ],
      totalElements: 0,
      loading: false,
    },
    elementSearchForm: {
      query: {},
      sortBy: ["startDay"],
      sortDesc: ["asc"],
    },

    elementEdit: false,
    elementEditForm: {
      valid: false,
    },
  }),

  methods: {
    resetElements(reset) {
      if (reset === true) {
        this.elementSearchForm.query = {};
        this.elementSearchForm.page = 1;
        this.elementSearchForm.itemsPerPage = 10;
        this.elementSearchForm.sortBy = ["startDay"];
        this.elementSearchForm.sortDesc = ["desc"];
      }
      this.searchElements();
    },

    searchElements() {
      console.log("searchElements", 1, this.elementSearchForm);

      OCPP_API.prices.search(this.elementSearchForm).then((r) => {
        console.log("searchElements", 2, r);
        this.elements = r.data._embedded.prices;
        this.elementSearch.totalElements = r.data.page.totalElements;
        this.elementSearch.loading = false;
      });
    },

    editElement(element) {
      this.element = element;
      this.elementEdit = true;

      setTimeout(() => {
        this.$refs.managementsPrice.active(this.element);
        this.$refs.pricesRevisions.active(this.element);
      }, 300);
    },
  },
  watch: {
    elementSearchForm: {
      handler: "searchElements",
      // deep: true, //즉시 변경시...
    },
  },
  mounted() {},
};
</script>

<style></style>
