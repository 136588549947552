<template>
  <v-card>
    <v-app-bar v-if="standalone" color="deep-purple accent-4" dense>
      <v-toolbar-title>과금 정책 편집 </v-toolbar-title>
    </v-app-bar>

    <v-card-subtitle>{{ standalone ? "&nbsp;" : "과금 정책" }}</v-card-subtitle>

    <v-card-text>
      <v-form ref="form">
        <v-row>
          <v-col v-if="isNew" cols="4">
            <v-select
              :items="exists"
              item-text="name"
              outlined
              label="과금 정책 불러오기"
              :filled="!standalone"
              :readonly="!standalone"
              return-object
              @change="selectExists"
            ></v-select>
          </v-col>

          <v-col :cols="isNew ? 8 : 12">
            <v-text-field
              v-model="element.name"
              :rules="rules.name"
              outlined
              label="과금 프로파일 명칭"
              :filled="!standalone"
              :readonly="!standalone"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>시간대</th>
                    <th>kw당 단가</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(n, idx) in prices24" :key="idx">
                    <td>{{ idx }} 시 ~ {{ idx + 1 }} 시</td>
                    <td>
                      <v-text-field
                        v-model="prices24[idx]"
                        class="mt-0 pt-0"
                        type="number"
                        :min="0"
                        :max="999"
                        style="width: 100px"
                        suffix="원"
                        hide-details
                        @input="autoChange(idx, prices24[idx])"
                        :filled="!standalone"
                        :readonly="!standalone"
                        dense
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr v-if="standalone">
                    <td colspan="2">
                      <v-switch
                        dense
                        hide-details
                        v-model="autoMenu"
                        label="다음 시간대 자동 입력"
                        :filled="!standalone"
                        :readonly="!standalone"
                      ></v-switch>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>

          <v-col cols="6">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="element.zoneOffset"
                  label="Your Browser TimeZone"
                  readonly
                  filled
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-menu
                  v-model="startMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  :disable="!standalone"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="element.startDay"
                      :rules="rules.startDay"
                      label="시작일"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :filled="!standalone"
                      @change="changeCronComment"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    no-title
                    scrollable
                    v-model="element.startDay"
                    @input="startMenu = false"
                    @change="changeCronComment"
                    :filled="!standalone"
                    :readonly="!standalone"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12">
                반복 옵션 : {{ element.cronComment }}

                <v-radio-group
                  v-model="element.cronType"
                  row
                  @change="changeCronComment"
                  :filled="!standalone"
                  :readonly="!standalone"
                >
                  <v-radio label="없음" value="0"></v-radio>
                  <v-radio label="매일 " value="1"></v-radio>
                  <v-radio label="매주 " value="2"></v-radio>
                  <v-radio label="매월" value="3"></v-radio>
                  <v-radio label="매년" value="4"></v-radio>
                </v-radio-group>
              </v-col>

              <v-col cols="12">
                <v-menu
                  v-model="endMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  :disable="!standalone"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="element.endDay"
                      label="종료일"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :filled="!standalone"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    no-title
                    scrollable
                    v-model="element.endDay"
                    @input="endMenu = false"
                    :min="element.startDay"
                    :filled="!standalone"
                    :readonly="!standalone"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  v-model="element.description"
                  label="설명"
                  outlined
                  required
                  :filled="!standalone"
                  :readonly="!standalone"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-divider v-if="standalone"></v-divider>
    <v-card-actions v-if="standalone">
      <v-spacer></v-spacer>
      <v-btn
        color="error"
        text
        @click="isNew ? createElement() : updateElement()"
      >
        {{ isNew ? "Create" : "Update" }}
      </v-btn>
      <v-btn color="primary" text @click="inactive(false)"> Close </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import OCPP_API from "@/app/env.ocpp.api.js";
import Validator from "../../app.validator.js";

const dayOfWeek = ["일", "월", "화", "수", "목", "금", "토"];
export default {
  name: "ManagementsPrice",

  props: {
    standalone: { type: Boolean },
  },

  data: () => ({
    isNew: false,
    element: {},

    prices24: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ],

    rules: {
      name: [Validator.required("이름이 필요합니다.")],
      startDay: [Validator.required("시작일이 필요합니다.")],
    },

    autoMenu: true,
    startMenu: false,
    endMenu: false,
    exists: [],
  }),

  methods: {
    active(element, isNew) {
      console.log(this.$options.name, "active", element, isNew);

      this.element = element;
      if (this.element.prices == undefined) {
        this.$set(
          this.element,
          "prices",
          [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0,
          ]
        );
      } else {
        this.prices24 = this.element.prices;
      }

      if (this.element.startDay == undefined) {
        this.$set(
          this.element,
          "startDay",
          this.$moment().format("YYYY-MM-DD")
        );
      }
      if (this.element.cronType == undefined) {
        this.$set(this.element, "cronType", "0");
      }
      if (this.element.cronComment == undefined) {
        this.$set(this.element, "cronComment", "없음");
      }
      if (this.element.zoneOffset == undefined) {
        this.$set(this.element, "zoneOffset", this.$moment().format("Z"));
      }

      this.isNew = isNew;
      this.repea = null;

      if (true === this.isNew) {
        this.loadExists();
      }
    },
    inactive(flag) {
      console.log(this.$options.name, "inactive", flag);
      this.$emit("inactive", flag);
    },

    loadExists() {
      const params = {
        page: 1,
        itemsPerPage: 10000,
        sortBy: ["timestamp"],
        sortDesc: ["desc"],
        query: {},
      };
      OCPP_API.prices.search(params).then((r) => {
        console.log("loadResource", 1, r);

        this.exists = r.data._embedded.prices;
      });
    },
    selectExists(p) {
      console.log("selectExists", 1, p);

      this.$set(this.element, "name", p.name + "(복사본)");
      this.$set(this.element, "startDay", p.startDay);
      this.$set(this.element, "endDay", p.endDay);
      this.$set(this.element, "cronType", p.cronType);
      this.$set(this.element, "cronComment", p.cronComment);
      this.$set(this.element, "zoneOffset", p.zoneOffset);
      this.$set(this.element, "description", p.description);
      this.$set(this.element, "prices", p.prices);

      this.prices24 = p.prices;
    },

    changeCronComment() {
      const start = this.$moment(this.element.startDay);

      if ("1" == this.element.cronType) {
        this.$set(this.element, "cronComment", "매일");
      } else if ("2" == this.element.cronType) {
        //매주
        this.$set(
          this.element,
          "cronComment",
          "매주 " + dayOfWeek[start.format("d")] + "요일"
        );
      } else if ("3" == this.element.cronType) {
        //매월
        this.$set(
          this.element,
          "cronComment",
          "매월 " + start.format("D") + " 일"
        );
      } else if ("4" == this.element.cronType) {
        //매년
        this.$set(
          this.element,
          "cronComment",
          "매년 " + start.format("M") + "월 " + start.format("D") + "일"
        );
      } else {
        this.$set(this.element, "cronComment", "없음");
      }
    },

    createElement() {
      if (!this.$refs.form.validate()) return;

      console.log(this.$options.name, "createElement");

      this.$set(this.element, "occupied", false);
      this.$set(this.element, "deleted", false);

      OCPP_API.prices.create(this.element).then((r) => {
        console.log(this.$options.name, "createElement3", r);
        this.inactive(true);
      });
    },
    updateElement() {
      console.log(this.$options.name, "updateElement");

      OCPP_API.prices.update(this.element).then((r) => {
        console.log(this.$options.name, "updateElement", r);
        this.inactive(true);
      });
    },

    autoChange(idx, val) {
      if (this.autoMenu != true) {
        return;
      }
      console.log(this.$options.name, "repeatChange", idx, val);
      this.$set(this.element.prices, idx, val);

      for (var i = idx + 1; i < 24; i++) {
        this.$set(this.prices24, i, val);
        this.$set(this.element.prices, i, val);
      }
    },
  },
};
</script>

<style></style>
